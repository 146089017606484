import { SettingsOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	Dialog,
	Drawer,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useUserDashboardConfigurationSettings } from 'components/pages/dashboard/hooks';
import { LinkCard } from 'components/pages/dashboard/linkCard';
import { presetQuickLinks } from 'components/pages/dashboard/quickLinks/presetQuickLinks';
import { useUserQuickLinks } from 'components/pages/dashboard/quickLinks/userQuickLinkHooks';
import { UserDashboardSettingsModal } from 'components/pages/dashboard/userDashboardSettingsModal';
import { EvoX } from 'components/pages/evoxchat/evox';
import { EvoxLogoIcon } from 'components/ui/icons';
import { PageTitle } from 'components/ui/page';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'utils/theme';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';
import { QuickLinks } from '../components/pages/dashboard/quickLinks/quickLinks';

export const SocialUserDashboard = () => {
	const quickLinksMethods = useUserQuickLinks();
	const { settings } = useUserDashboardConfigurationSettings();
	const [openDashboardSettingsModal, setOpenDashboardSettingsModal] = useState(false);

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();

	// keep track of the size of the header text
	// this is used to keep the links in line with the evox card
	const headerRef = useRef<HTMLDivElement>(null);
	const [headerHeight, setHeaderHeight] = useState(60);
	useEffect(() => {
		if (!headerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (!headerRef.current) return;
			setHeaderHeight(headerRef.current.clientHeight);
		});
		resizeObserver.observe(headerRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	const [evoxOpen, setEvoxOpen] = useState(false);

	const textColor = settings?.primaryColor
		? theme.palette.getContrastText(settings.primaryColor)
		: undefined;

	return (
		<Box position="relative" height="100%" overflow="auto" id="main-content">
			<PageTitle title="Dashboard" />
			{!isMediumOrSmallerScreen && (
				<Stack
					width="100%"
					px={{ xs: 1.5, md: 3, lg: 6 }}
					py={{ xs: 3, md: 4 }}
					pt={{ xs: 0, md: 4 }}
					margin={0}
					position="absolute"
					display="flex"
					alignItems="flex-end"
					flexDirection="column">
					<IconButton
						onClick={() => {
							setOpenDashboardSettingsModal(true);
						}}>
						<SettingsOutlined fontSize="large" htmlColor={textColor} />
					</IconButton>
				</Stack>
			)}
			<Stack
				direction={{ xs: 'column', md: 'row' }}
				px={{ xs: 1.5, md: 3, lg: 6 }}
				py={{ xs: 3, md: 4 }}
				pt={{ xs: 0, md: 4 }}
				spacing={{ xs: 3, sm: 4 }}
				justifyContent={{ xs: undefined, md: 'center' }}
				height="100%"
				overflow="auto"
				position={{ xs: 'relative', md: 'static' }}>
				{settings ? (
					<Box
						position="absolute"
						height={headerHeight + 140}
						top={0}
						left={0}
						right={0}
						zIndex={-100}
						sx={{ backgroundColor: settings.primaryColor }}
					/>
				) : (
					<Skeleton
						width="100%"
						height={headerHeight + 140}
						variant="rectangular"
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							zIndex: -100,
						}}
					/>
				)}
				<Stack
					width={{ xs: undefined, md: '640px' }}
					spacing={{ xs: 3, md: 4 }}
					position={{ xs: undefined, md: 'sticky' }}
					top={0}>
					<Stack>
						<Box ref={headerRef}>
							{settings?.title ? (
								<Typography variant="h1" color={textColor} noWrap>
									{settings?.title}
								</Typography>
							) : (
								<Typography variant="h1" color="white" noWrap>
									<Skeleton variant="text" width={150} />
								</Typography>
							)}
							{settings?.subTitle ? (
								<Typography color={textColor}>{settings?.subTitle}</Typography>
							) : (
								<Typography>
									<Skeleton variant="text" width={200} />
								</Typography>
							)}
						</Box>
					</Stack>
					{isMediumOrSmallerScreen && (
						<Button
							variant="outlined"
							startIcon={<SettingsOutlined />}
							onClick={() => setOpenDashboardSettingsModal(true)}>
							Configure Dashboard Theme
						</Button>
					)}
					{isMediumOrSmallerScreen ? (
						<Button
							variant="outlined"
							startIcon={<EvoxLogoIcon />}
							onClick={() => setEvoxOpen(true)}>
							Chat with EVOX
						</Button>
					) : (
						<Card sx={{ flex: 1 }}>
							<EvoX />
						</Card>
					)}
				</Stack>
				<Stack
					width={{ xs: undefined, md: '1100px' }}
					spacing={{ xs: 3, md: 4 }}
					alignSelf={{ xs: undefined, md: 'flex-start' }}>
					{!isMediumOrSmallerScreen && <Box height={headerHeight} />}
					<Box
						display="grid"
						gridTemplateColumns={{
							xs: 'repeat(2, 1fr)',
							lg: 'repeat(3, 1fr)',
							xl: 'repeat(4, 1fr)',
						}}
						gridAutoRows="1fr"
						gap={2}>
						<LinkCard
							Icon={'img/FT-logo.svg'}
							text="FileTrac"
							link="app/legacy/linked-companies"
							accentColor={settings?.secondaryColor}
						/>
						<LinkCard
							Icon={'/img/conversation-insurance-color-logo.svg'}
							text="Conversation Insurance"
							link="https://www.conversation-insurance.com/"
							accentColor={settings?.secondaryColor}
						/>
						<LinkCard
							Icon={'/img/conversations-logo.png'}
							text="Conversations"
							disabled
						/>
						<LinkCard Icon={'/img/marketplace-logo.png'} text="Marketplace" disabled />
					</Box>
					<QuickLinks
						methods={quickLinksMethods}
						canEdit
						presetQuickLinks={presetQuickLinks}
					/>
				</Stack>
			</Stack>
			{/* EvoX popup for mobile view */}
			{evoxOpen && (
				<Dialog open={evoxOpen} fullScreen>
					<EvoX onClose={() => setEvoxOpen(false)} />
				</Dialog>
			)}
			{isMediumOrSmallerScreen ? (
				<Drawer open={openDashboardSettingsModal} anchor="bottom">
					<UserDashboardSettingsModal
						onClose={() => setOpenDashboardSettingsModal(false)}
					/>
				</Drawer>
			) : (
				<Dialog open={openDashboardSettingsModal} fullWidth>
					<UserDashboardSettingsModal
						onClose={() => setOpenDashboardSettingsModal(false)}
					/>
				</Dialog>
			)}
		</Box>
	);
};
