import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	Mutation,
	Notification,
	NotificationState,
	NotificationType,
	Query,
	QueryNotificationsArgs,
} from 'middleware-types';
import { useState } from 'react';
import { client } from './apollo';

const PAGE_SIZE = 50;

/**
 * The Notifications query.
 */
export const NOTIFICATIONS = gql`
	${EMBLEM_FIELDS}
	query notifications($limit: Int!, $offset: Int!, $markAllAsSeen: Boolean!) {
		notifications(limit: $limit, offset: $offset, markAllAsSeen: $markAllAsSeen) {
			items {
				id
				recipientUserId
				displayText
				createdUtc
				relatedEntityId
				relatedEntityType
				readCount
				unreadCount
				type
				state
				targetEntityId
				relatedEntityEmblem {
					...EmblemFields
				}
			}
			totalCount
			totalUnseenCount
		}
	}
`;

/**
 * The mutation to mark a notification as read by notification id.
 */
export const NOTIFICATION_MARK_READ_BY_ID = gql`
	mutation notificationMarkReadById($notificationId: ID!) {
		notificationMarkReadById(notificationId: $notificationId)
	}
`;

/**
 * The mutation to mark a notification as read by grouping.
 */
export const NOTIFICATION_MARK_READ_BY_GROUPING = gql`
	mutation notificationMarkReadByGrouping(
		$relatedEntityId: ID!
		$notificationType: NotificationType!
		$inAppText: String!
	) {
		notificationMarkReadByGrouping(
			relatedEntityId: $relatedEntityId
			notificationType: $notificationType
			inAppText: $inAppText
		)
	}
`;

/**
 * The mutation to mark all notifications as read.
 */
export const NOTIFICATION_MARK_ALL_READ = gql`
	mutation notificationMarkAllRead($types: [NotificationType!]) {
		notificationMarkAllRead(types: $types)
	}
`;

/**
 * The mutation to dismiss a notification by notification id.
 */
export const NOTIFICATION_DISMISS_BY_ID = gql`
	mutation notificationDismissById($notificationId: ID!) {
		notificationDismissById(notificationId: $notificationId)
	}
`;

/**
 * The mutation to dismiss a grouping of notifications.
 */
export const NOTIFICATION_DISMISS_BY_GROUPING = gql`
	mutation notificationDismissByGrouping(
		$relatedEntityId: ID!
		$notificationType: NotificationType!
		$inAppText: String!
	) {
		notificationDismissByGrouping(
			relatedEntityId: $relatedEntityId
			notificationType: $notificationType
			inAppText: $inAppText
		)
	}
`;

/**
 * The mutation to dismiss all notifications.
 */
export const NOTIFICATION_DISMISS_ALL = gql`
	mutation notificationDismissAll($types: [NotificationType!]) {
		notificationDismissAll(types: $types)
	}
`;

/**
 * Hook to get the user's notifications.
 * @param pageSize
 * @param offset
 * @param markAllAsSeen
 * @returns
 */
export const useNotificationsQuery = () => {
	const toast = useToast();
	const { data, error, loading, fetchMore } = useQuery<
		Pick<Query, 'notifications'>,
		QueryNotificationsArgs
	>(NOTIFICATIONS, {
		variables: {
			offset: 0,
			limit: PAGE_SIZE,
			markAllAsSeen: true,
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
		onCompleted: () => {
			client.cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getUserBadgeCounts',
			});

			client.cache.gc();
		},
		fetchPolicy: 'network-only',
	});

	const notifications = data?.notifications.items ?? [];
	const totalDeduplicatedCount = data?.notifications.totalCount ?? 0;
	const totalUnseenCount = data?.notifications.totalUnseenCount ?? 0;

	const [isRefetching, setIsRefetching] = useState(false);
	const loadMore = async () => {
		if (loading) return;
		if (isRefetching) return;
		setIsRefetching(true);
		await fetchMore({
			variables: {
				offset: notifications.length,
				limit: PAGE_SIZE,
				markAllAsSeen: true,
			},
		});
		setIsRefetching(false);
	};

	return {
		notifications,
		totalDeduplicatedCount,
		totalUnseenCount,
		loading,
		loadMore,
	};
};

/**
 * The hook to dismiss all notifications.
 * @returns
 */
export const useNotificationDismissAllMutation = () => {
	const toast = useToast();
	const [notificationDismissAll, { error }] = useMutation<
		Pick<Mutation, 'notificationDismissAll'>
	>(NOTIFICATION_DISMISS_ALL, {
		refetchQueries: [
			{
				query: NOTIFICATIONS,
				variables: {
					limit: 0,
					offset: 0,
					markAllAsSeen: false,
				},
			},
		],
		awaitRefetchQueries: true,
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const dismissAllNotifications = async () => {
		return notificationDismissAll();
	};

	return { dismissAllNotifications };
};

/**
 * The hook to mark all notifications as read.
 * @returns
 */
export const useNotificationMarkAllReadMutation = () => {
	const client = useApolloClient();
	const toast = useToast();
	const [notificationMarkAllRead, { error }] = useMutation<
		Pick<Mutation, 'notificationMarkAllRead'>
	>(NOTIFICATION_MARK_ALL_READ, {
		refetchQueries: [
			{
				query: NOTIFICATIONS,
				variables: {
					limit: PAGE_SIZE,
					offset: 0,
					markAllAsSeen: false,
				},
			},
		],
		update: (cache, data) => {
			const cacheData: Notification[] = client.readQuery({
				query: NOTIFICATIONS,
			})?.notifications?.notifications?.items;
			for (let i = 0; i < cacheData?.length; i++) {
				cache.writeFragment({
					id: `Notification:${cacheData[i].id}`,
					fragment: gql`
						fragment newState on Notification {
							state
						}
					`,
					data: {
						state: NotificationState.Read,
					},
				});
			}
			return data;
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const readAllNotifications = async () => {
		return notificationMarkAllRead();
	};

	return { readAllNotifications };
};

/**
 * The hook to mark a notification as read by Id.
 * @param notificationId
 * @returns
 */
export const useNotificationMarkReadByIdMutation = (notificationId: string) => {
	const toast = useToast();
	const [notificationMarkRead, { error }] = useMutation<
		Pick<Mutation, 'notificationMarkReadById'>
	>(NOTIFICATION_MARK_READ_BY_ID, {
		errorPolicy: 'all',
		update: (cache) => {
			cache.modify({
				id: `Notification:${notificationId}`,
				fields: {
					state() {
						return NotificationState.Read;
					},
					unreadCount() {
						return 0;
					},
					readCount(val) {
						return val + 1;
					},
				},
			});
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const readNotificationById = async () => {
		return notificationMarkRead({
			variables: {
				notificationId,
			},
		});
	};

	return { readNotificationById };
};

/**
 * The hook to mark a notification as read by related entity.
 * @param notificationId
 * @returns
 */
export const useNotificationMarkReadByGrouping = (
	relatedEntityId: string,
	notificationType: NotificationType,
	inAppText: string,
	notificationId: string // Needed to update cache
) => {
	const toast = useToast();
	const [notificationMarkRead, { error }] = useMutation<
		Pick<Mutation, 'notificationMarkReadByGrouping'>
	>(NOTIFICATION_MARK_READ_BY_GROUPING, {
		errorPolicy: 'all',
		update: (cache, data) => {
			const oldstate: { readCount: number | null; unreadCount: number | null } | null =
				cache.readFragment({
					id: `Notification:${notificationId}`,
					fragment: gql`
						fragment oldState on Notification {
							readCount
							unreadCount
						}
					`,
				});
			cache.writeFragment({
				id: `Notification:${notificationId}`,
				fragment: gql`
					fragment newState on Notification {
						state
						readCount
						unreadCount
					}
				`,
				data: {
					state: NotificationState.Read,
					readCount: (oldstate?.readCount ?? 0) + (oldstate?.unreadCount ?? 0),
					unreadCount: 0,
				},
			});
			return data;
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const readNotificationByGrouping = async () => {
		return notificationMarkRead({
			variables: {
				relatedEntityId,
				notificationType,
				inAppText,
			},
		});
	};

	return { readNotificationByGrouping };
};

/**
 * The hook to dismiss a notification by Id.
 * @param notificationId
 * @returns
 */
export const useNotificationDismissByIdMutation = (notificationId: string) => {
	const toast = useToast();
	const [notificationDismissById, { error }] = useMutation<
		Pick<Mutation, 'notificationDismissById'>
	>(NOTIFICATION_DISMISS_BY_ID, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'notifications',
			});
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const dismissNotificationById = async () => {
		return notificationDismissById({
			variables: {
				notificationId,
			},
		});
	};

	return { dismissNotificationById };
};

/**
 * The hook to dismiss a notification by grouping.
 * @param notificationId
 * @returns
 */
export const useNotificationDismissByGroupingMutation = (
	relatedEntityId: string,
	notificationType: NotificationType,
	inAppText: string
) => {
	const toast = useToast();
	const [notificationDismissByGrouping, { error }] = useMutation<
		Pick<Mutation, 'notificationDismissByGrouping'>
	>(NOTIFICATION_DISMISS_BY_GROUPING, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'notifications',
			});
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const dismissNotificationByGrouping = async () => {
		return notificationDismissByGrouping({
			variables: {
				relatedEntityId,
				notificationType,
				inAppText,
			},
		});
	};

	return { dismissNotificationByGrouping };
};
