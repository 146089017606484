import { DeleteOutlined, MarkEmailReadOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Badge,
	Box,
	Divider,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { Notification, NotificationState } from 'middleware-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	useNotificationDismissByIdMutation,
	useNotificationMarkReadByIdMutation,
	useNotificationMarkReadByGrouping,
	useNotificationDismissByGroupingMutation,
} from 'utils/useNotifications';
import { EmblemAvatar } from '../emblem/emblem-avatar';

interface NotificationListItemProps {
	notification: Notification;
	onClose: () => void;
}

export const NotificationListItem = ({ notification, onClose }: NotificationListItemProps) => {
	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
	const menuOpen = Boolean(menuAnchor);
	const closeMenu = () => setMenuAnchor(null);

	const { dismissNotificationById } = useNotificationDismissByIdMutation(notification.id);
	const { dismissNotificationByGrouping } = useNotificationDismissByGroupingMutation(
		notification.relatedEntityId,
		notification.type,
		notification.displayText
	);
	const { readNotificationById } = useNotificationMarkReadByIdMutation(notification.id);
	const { readNotificationByGrouping } = useNotificationMarkReadByGrouping(
		notification.relatedEntityId,
		notification.type,
		notification.displayText,
		notification.id
	);

	const isRead = notification.state === NotificationState.Read;

	const readNotification = () => {
		// If the notification represents multiple unread NotifiedUsers, use mark read by related entity
		if (notification.unreadCount > 1) {
			readNotificationByGrouping();
		} else if (!isRead) {
			readNotificationById();
		}
	};

	const handleRead = () => {
		readNotification();
		onClose();
	};

	return (
		<>
			<ListItem disableGutters disablePadding>
				<ListItemButton
					onClick={handleRead}
					component={Link}
					to={`app/redirect/${notification.relatedEntityType}/${notification.relatedEntityId}`}>
					<Stack width="100%" direction="row" alignItems="center" spacing={2}>
						<Box
							height={8}
							width={8}
							borderRadius="50%"
							bgcolor={isRead ? 'transparent' : 'primary.main'}
						/>
						{notification.relatedEntityEmblem && (
							<EmblemAvatar emblem={notification.relatedEntityEmblem} />
						)}
						<Box flex={1}>
							<Typography variant="h6" color={isRead ? 'neutral.600' : 'primary'}>
								{notification.displayText}
							</Typography>
							<Typography variant="subtitle2">
								{formatDistance(new Date(notification.createdUtc), new Date(), {
									addSuffix: true,
								})}
							</Typography>
						</Box>
						{notification.readCount + notification.unreadCount > 1 && (
							<Stack direction="row" spacing={3}>
								<Badge
									sx={{
										'& .MuiBadge-badge': {
											color: 'black',
											backgroundColor: 'neutral.100',
										},
									}}
									badgeContent={
										notification.readCount >= 1
											? notification.readCount
											: undefined
									}></Badge>
								<Badge
									sx={{
										'& .MuiBadge-badge': {
											color: 'white',
											backgroundColor: 'primary.main',
										},
									}}
									badgeContent={
										notification.unreadCount >= 1
											? notification.unreadCount
											: undefined
									}></Badge>
							</Stack>
						)}
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setMenuAnchor(e.currentTarget);
							}}
							onMouseDown={(e) => e.stopPropagation()}>
							<MoreHoriz />
						</IconButton>
					</Stack>
				</ListItemButton>
			</ListItem>
			<Divider />
			{/** Menu */}
			<Menu open={menuOpen} anchorEl={menuAnchor} onClose={closeMenu}>
				{!isRead && (
					<MenuItem
						onClick={() => {
							readNotification();
							closeMenu();
						}}>
						<ListItemIcon>
							<MarkEmailReadOutlined />
						</ListItemIcon>
						<ListItemText>Mark as read</ListItemText>
					</MenuItem>
				)}
				<MenuItem
					onClick={() => {
						if (notification.readCount + notification.unreadCount > 1) {
							dismissNotificationByGrouping();
						} else {
							dismissNotificationById();
						}
						closeMenu();
					}}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText>Dismiss</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};
