import { Avatar, Box, Card, CardActionArea, Stack, Tooltip } from '@mui/material';
import { QuickLinkCard } from 'components/pages/dashboard/quickLinks/quickLinkCard';
import { useUserQuickLinks } from 'components/pages/dashboard/quickLinks/userQuickLinkHooks';
import { EvoX } from 'components/pages/evoxchat/evox';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SideRail = ({ children }: { children: ReactNode }) => {
	const [showEvox, setShowEvox] = useState(false);
	const quickLinksMethods = useUserQuickLinks();
	const { quickLinks, loading } = quickLinksMethods;
	const navigate = useNavigate();

	return (
		<Stack height="100%" width="100%" flexDirection="row">
			{!loading && (
				<Stack
					alignSelf="stretch"
					position="relative"
					sx={{
						backgroundColor: 'background.paper',
						borderRight: '1px solid',
						borderRightColor: 'neutral.200',
					}}>
					<Card
						sx={{
							borderRadius: 0,
							boxShadow: 0,
							width: '49px',
							height: '49px',
						}}>
						<CardActionArea onClick={() => setShowEvox((prev) => !prev)}>
							<Tooltip title="EVO-X" leaveDelay={0} placement="right">
								<Avatar
									src="/img/evoxLogo.svg"
									sx={{
										backgroundColor: 'transparent',
										width: '100%',
										height: '100%',
										padding: 1,
									}}
								/>
							</Tooltip>
						</CardActionArea>
					</Card>
					<Card
						sx={{
							borderRadius: 0,
							boxShadow: 0,
							width: '49px',
							height: '49px',
						}}>
						<CardActionArea onClick={() => navigate('/app/legacy/linked-companies')}>
							<Tooltip title="FileTrac" leaveDelay={0} placement="right">
								<Avatar
									src="/img/FT-logo.svg"
									sx={{
										backgroundColor: 'transparent',
										width: '100%',
										height: '100%',
										padding: 1,
									}}
								/>
							</Tooltip>
						</CardActionArea>
					</Card>
					<QuickLinkCard
						methods={quickLinksMethods}
						quickLink={{
							id: 'conversation-insurance-quicklink',
							title: 'Conversation Insurance',
							url: 'https://www.conversation-insurance.com/',
							isPresetLink: true,
							presetIcon: '/img/conversation-insurance-color-logo.svg',
						}}
						canEdit={false}
						isForSideRail
						isLastPreset
					/>
					{/* There are currently no preset quicklinks */}
					{/* {presetQuickLinks.map((quickLink) => { */}
					{/* 	return ( */}
					{/* 		<QuickLinkCard */}
					{/* 			key={quickLink.id} */}
					{/* 			methods={quickLinksMethods} */}
					{/* 			quickLink={quickLink} */}
					{/* 			canEdit={false} */}
					{/* 			isForSideRail */}
					{/* 		/> */}
					{/* 	); */}
					{/* })} */}
					{quickLinks.map((quickLink) => {
						return (
							<QuickLinkCard
								key={quickLink.id}
								methods={quickLinksMethods}
								quickLink={quickLink}
								canEdit={false}
								isForSideRail
							/>
						);
					})}
				</Stack>
			)}
			{showEvox && (
				<Box position="relative">
					<Stack
						position="absolute"
						height="100%"
						width={{ xs: undefined, sm: '400px' }}
						zIndex={999}>
						<Card sx={{ height: '100%', borderRadius: '0' }}>
							<EvoX onClose={() => setShowEvox(false)} />
						</Card>
					</Stack>
				</Box>
			)}
			<Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
				{children}
			</Box>
		</Stack>
	);
};
