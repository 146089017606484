import { BusinessOutlined, Cancel, ForumOutlined } from '@mui/icons-material';
import { Avatar, AvatarProps, Badge, BadgeProps } from '@mui/material';
import { Emblem, EmblemEntityType } from 'middleware-types';
import { EmblemDropdown } from './emblem-dropdown/emblem-dropdown';
import { useEmblem } from './hooks';

export interface EmblemAvatarBaseProps extends AvatarProps {
	size?: number;
	noDropdown?: boolean;
	showBadge?: boolean;
}

interface EmblemAvatarProps extends EmblemAvatarBaseProps {
	entityId: string;
	entityType: EmblemEntityType;
}

const _EmblemAvatar = ({
	entityId,
	entityType,
	size = 40,
	noDropdown = false,
	showBadge = true,
	sx,
	...avatarProps
}: EmblemAvatarProps) => {
	const { emblem, src, onError } = useEmblem(entityId, entityType);

	let badgeOverlap: BadgeProps['overlap'] = undefined;
	let variant: AvatarProps['variant'] = undefined;
	let children: AvatarProps['children'] = undefined;
	switch (entityType) {
		case EmblemEntityType.User:
			badgeOverlap = 'circular';
			variant = 'circular';
			children = emblem?.extraData?.initials;
			break;
		case EmblemEntityType.Organization:
			badgeOverlap = 'rectangular';
			variant = 'rounded';
			children = <BusinessOutlined sx={{ fontSize: '1.25em' }} />;
			break;
		case EmblemEntityType.Conversation:
			badgeOverlap = 'rectangular';
			variant = 'circular';
			children = <ForumOutlined sx={{ fontSize: '1.25em' }} />;
			break;
	}

	const props: AvatarProps = {
		src,
		variant,
		onError,
		children,
		sx: { height: size, width: size, fontSize: size / 2, ...sx },
		...avatarProps,
	};

	return (
		<EmblemDropdown emblem={emblem} disabled={noDropdown}>
			<Badge
				invisible={!emblem?.deactivated || !showBadge}
				overlap={badgeOverlap}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				badgeContent={
					<Cancel
						color="error"
						sx={{
							fontSize: size * 0.35,
							backgroundColor: 'neutral.50',
							borderRadius: '50%',
						}}
					/>
				}>
				<Avatar
					{...props}
					sx={
						emblem?.deactivated
							? {
									...props.sx,
									border: '2px solid',
									borderColor: 'error.500',
							  }
							: props.sx
					}
				/>
			</Badge>
		</EmblemDropdown>
	);
};

/**
 * wrappers for the emblem avatar component
 */
export const EmblemAvatar = ({
	emblem,
	entityId,
	entityType,
	...rest
}: EmblemAvatarBaseProps &
	(
		| { emblem: Emblem | undefined; entityId?: never; entityType?: never }
		| { emblem?: never; entityId: string; entityType: EmblemEntityType }
	)) => {
	if (entityId) return <_EmblemAvatar entityId={entityId} entityType={entityType} {...rest} />;
	if (emblem === undefined) return null;
	return <_EmblemAvatar entityId={emblem.id} entityType={emblem.entityType} {...rest} />;
};

export const UserEmblemAvatar = ({ id, ...rest }: EmblemAvatarBaseProps & { id: string }) => {
	return <_EmblemAvatar entityId={id} entityType={EmblemEntityType.User} {...rest} />;
};

export const OrgEmblemAvatar = ({ id, ...rest }: EmblemAvatarBaseProps & { id: string }) => {
	return <_EmblemAvatar entityId={id} entityType={EmblemEntityType.Organization} {...rest} />;
};
